import Mixins from "../../Mixins.js";
export default {
  name: "EditDepositChartOfAccount",
  mixins: [Mixins],
  data() {
    return {
      identity: {
        depositId: "",
      },
      property: {
        modal: {
          showModalEditDepositChartOfAccount: false,
        },
        listElement: {
          depositChartOfAccount: {
            rows: 0,
            currentPage: 1,
            perPage: 5,
            downloading: false,
            message: "",
          },
        },
        animation: {
          addDepositChartOfAccount: {
            isLoading: false,
          },
          editDepositChartOfAccount: {
            isLoading: false,
          },
        },
      },
      dataForm: {
        add: {
          depositId: "",
          chartOfAccountId: "",
          productLedgerId: "",
          isActive: false,
          productCategoryName: "",
          akunLedgerDeskripsi: "",
          depositChartOfAccountId: "",
          mutationPosition: "",
          pairMutationPosition: "",
          pairMutationPosition: "",
          transactionCodeId: "",
          pairType: "",
        },
        edit: {
          depositId: "",
          chartOfAccountId: "",
          productLedgerId: "",
          isActive: false,
          productCategoryName: "",
          akunLedgerDeskripsi: "",
          depositChartOfAccountId: "",
          mutationPosition: "",
          pairMutationPosition: "",
          pairMutationPosition: "",
          transactionCodeId: "",
          pairType: "",
        },
      },
      options: {
        parameterLedgerDeposito: [],
        akunLedger: [],
        posisiMutasi: [],
        transactionCode: [],
      },
      table: {
        data: {
          depositChartOfAccount: [],
        },
      },
    };
  },
  methods: {
    changePairTypeAddDepositChartOfAccount() {
      if (this.dataForm.add.pairType === "SAVING") {
        this.dataForm.add.pairChartOfAccountId = "";
      }
    },
    changePairTypeEditDepositChartOfAccount() {
      if (this.dataForm.edit.pairType === "SAVING") {
        this.dataForm.edit.pairChartOfAccountId = "";
      }
    },
    getProductCategoryNameDepositChartOfAccount() {
      this.$store.dispatch("GET_REFERENCE_PRODUCT_CATEGORY").then((resp) => {
        resp.data.data.map((i) => {
          if (
            i.productCategoryId ===
            this.fetchAppSession("@vue-session/config-bundle")
              .VUE_APP_PRODUCT_CATEGORY_DEPOSITO
          ) {
            this.dataForm.add.productCategoryName = i.productCategoryName;
          }
        });
      });
    },
    getReferenceProductLedger() {
      this.$store
        .dispatch("GET_PRODUCT_LEDGER_BY_PRODUCT_CATEGORY_ID", {
          productCategoryId: this.fetchAppSession("@vue-session/config-bundle")
            .VUE_APP_PRODUCT_CATEGORY_DEPOSITO,
        })
        .then((resp) => {
          resp.data.data.map((i) => {
            const value = i.productLedgerId;
            const text = i.productLedgerName;
            this.options.parameterLedgerDeposito.push({ value, text });
          });
        });
    },
    getReferenceChartOfAccount() {
      this.$store
        .dispatch("GET_REFERENCE_FROM_MASTER_PARAMETER", {
          params: {
            description: "",
            page: 0,
          },
          url: "chart-of-account",
        })
        .then((response) => {
          response.data.data.content.map((i) => {
            const value = i.chartOfAccountId;
            const text = `${i.chartOfAccountCode} - ${i.description}`;
            const description = i.description;
            this.options.akunLedger.push({
              value,
              text,
              description,
            });
          });
        });
    },
    async getDepositChartOfAccount() {
      this.resetListElementDepositChartOfAccount();
      this.property.listElement.depositChartOfAccount.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-chart-of-account",
          payload: {
            depositId: !this.identity.depositId ? "" : this.identity.depositId,
            page: 0,
            size: this.property.listElement.depositChartOfAccount.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.depositChartOfAccount = resp.data.data.content;
            this.property.listElement.depositChartOfAccount.rows =
              resp.data.data.totalElements;
          } else {
            this.property.listElement.depositChartOfAccount.message =
              resp.data.message;
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetDepositChartOfAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.depositChartOfAccount.downloading = false;
        }, timeout);
      }
    },
    resetListElementDepositChartOfAccount() {
      this.table.data.depositChartOfAccount = [];
      this.property.listElement.depositChartOfAccount.rows = 0;
      this.property.listElement.depositChartOfAccount.currentPage = 1;
    },
    handleErrorGetDepositChartOfAccount(error) {
      console.log(error.response);
      this.table.data.depositChartOfAccount = [];
      this.property.listElement.depositChartOfAccount.message = error.response
        ? `Gagal Mengunduh Data : ${error.response.statusText}`
        : "Terjadi Kesalahan";
    },
    appendOptionsPosisiMutasi() {
      this.options.posisiMutasi = [
        { value: "", text: "-- Pilih --" },
        { value: "D", text: "Debet" },
        { value: "C", text: "Credit" },
      ];
    },
    changeAddAkunLedger() {
      this.dataForm.add.akunLedgerDeskripsi = "";
      this.options.akunLedger.map((i) => {
        if (i.value === this.dataForm.add.chartOfAccountId) {
          this.dataForm.add.akunLedgerDeskripsi = i.description;
        }
      });
    },
    changeEditAkunLedger() {
      this.dataForm.edit.akunLedgerDeskripsi = "";
      this.options.akunLedger.map((i) => {
        if (i.value === this.dataForm.edit.chartOfAccountId) {
          this.dataForm.edit.akunLedgerDeskripsi = i.description;
        }
      });
    },
    async getReferenceTransactionCode() {
      try {
        const resp = await this.$store.dispatch(
          "GET_REFERENCE_FROM_MASTER_PARAMETER",
          {
            params: {
              page: 0,
              name: "",
            },
            url: "transaction-code",
          }
        );
        if (resp.data.code === "SUCCESS") {
          resp.data.data.content.map((i) => {
            const text = `${i.transactionCode} - ${i.transactionName}`;
            const value = i.transactionCodeId;
            this.options.transactionCode.push({ text, value });
          });
        }
      } catch (error) {}
    },
    async changePaginationDepositChartOfAccount(event) {
      this.table.data.depositChartOfAccount = [];
      this.property.listElement.depositChartOfAccount.downloading = true;
      const timeout = 500;
      try {
        const resp = await this.$store.dispatch({
          type: "GET_LIST_DATA",
          endPoint: "transaction",
          reqUrl: "deposit-chart-of-account",
          payload: {
            depositId: !this.identity.depositId ? "" : this.identity.depositId,
            page: event - 1,
            size: this.property.listElement.depositChartOfAccount.perPage,
          },
        });
        setTimeout(() => {
          if (resp.data.code === "SUCCESS") {
            this.table.data.depositChartOfAccount = resp.data.data.content;
            this.property.listElement.depositChartOfAccount.rows =
              resp.data.data.totalElements;
          } else {
            this.handleFailedGetDataDepositChartOfAccount(resp);
          }
        }, timeout);
      } catch (error) {
        setTimeout(() => {
          this.handleErrorGetDepositChartOfAccount(error);
        }, timeout);
      } finally {
        setTimeout(() => {
          this.property.listElement.depositChartOfAccount.downloading = false;
        }, timeout);
      }
    },
    handleFailedGetDataDepositChartOfAccount(resp) {
      this.table.data.depositChartOfAccount = [];
      this.property.listElement.depositChartOfAccount.message =
        resp.data.message;
      this.property.listElement.depositChartOfAccount.rows = 0;
    },
    async addDepositChartOfAccount() {
      const payload = {
        depositId: this.identity.depositId,
        chartOfAccountId: this.dataForm.add.chartOfAccountId,
        productLedgerId: this.dataForm.add.productLedgerId,
        mutationPosition: this.dataForm.add.mutationPosition,
        pairChartOfAccountId: this.dataForm.add.pairChartOfAccountId,
        pairMutationPosition: this.dataForm.add.pairMutationPosition,
        transactionCodeId: this.dataForm.add.transactionCodeId,
        isActive: this.dataForm.add.isActive,
        pairType: this.dataForm.add.pairType,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Simpan Parameter Ledger ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.addDepositChartOfAccount.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "transaction",
                reqUrl: "deposit-chart-of-account",
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  const toastBuefy = this.$buefy.toast.open(
                    this.$NotificationUtils.success
                  );
                  toastBuefy.$on("close", () => {
                    this.resetFormDepositChartOfAccount("ADD");
                    this.getDepositChartOfAccount();
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: `${resp.data.message}`,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.addDepositChartOfAccount.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    resetFormDepositChartOfAccount(type) {
      switch (type) {
        case "ADD":
          this.dataForm.add.productLedgerId = "";
          this.dataForm.add.isActive = "";
          this.dataForm.add.depositId = "";
          this.dataForm.add.chartOfAccountId = "";
          this.dataForm.add.akunLedgerDeskripsi = "";
          this.dataForm.add.depositChartOfAccountId = "";
          this.dataForm.add.mutationPosition = "";
          this.dataForm.add.pairChartOfAccountId = "";
          this.dataForm.add.pairMutationPosition = "";
          this.dataForm.add.transactionCodeId = "";
          this.dataForm.add.pairType = "";

          break;

        case "EDIT":
          this.dataForm.edit.productLedgerId = "";
          this.dataForm.edit.isActive = "";
          this.dataForm.edit.depositId = "";
          this.dataForm.edit.chartOfAccountId = "";
          this.dataForm.edit.akunLedgerDeskripsi = "";
          this.dataForm.edit.depositChartOfAccountId = "";
          this.dataForm.edit.productCategoryName = "";
          this.dataForm.edit.mutationPosition = "";
          this.dataForm.edit.pairChartOfAccountId = "";
          this.dataForm.edit.pairMutationPosition = "";
          this.dataForm.edit.transactionCodeId = "";
          this.dataForm.edit.pairType = "";
          break;

        default:
          return;
          break;
      }
    },
    closeModalEditDepositChartOfAccount() {
      this.resetFormDepositChartOfAccount("EDIT");
      this.property.modal.showModalEditDepositChartOfAccount = false;
    },
    async getDepositChartOfAccountById(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.resetFormDepositChartOfAccount("EDIT");
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "transaction",
            reqUrl:
              "deposit-chart-of-account/" + props.row.depositChartOfAccountId,
          });

          if (resp.data.code === "SUCCESS") {
            this.dataForm.edit.mutationPosition =
              resp.data.data.mutationPosition;
            this.dataForm.edit.productCategoryName = resp.data.data.mdeposit
              ? resp.data.data.mdeposit.mproductCategory
                ? resp.data.data.mdeposit.mproductCategory.productCategoryName
                : ""
              : "";
            this.dataForm.edit.productLedgerId = resp.data.data.mproductLedger
              ? resp.data.data.mproductLedger.productLedgerId
              : "";
            this.dataForm.edit.isActive = resp.data.data.isActive;
            this.dataForm.edit.depositId = resp.data.data.mdeposit
              ? resp.data.data.mdeposit.depositId
              : "";
            this.dataForm.edit.chartOfAccountId = resp.data.data.mchartOfAccount
              ? resp.data.data.mchartOfAccount.chartOfAccountId
              : "";
            this.dataForm.edit.akunLedgerDeskripsi = resp.data.data
              .mchartOfAccount
              ? resp.data.data.mchartOfAccount.description
              : "";
            this.dataForm.edit.pairChartOfAccountId = resp.data.data
              .pairMChartOfAccount
              ? resp.data.data.pairMChartOfAccount.chartOfAccountId
              : "";
            this.dataForm.edit.pairMutationPosition = resp.data.data
              .pairMutationPosition
              ? resp.data.data.pairMutationPosition
              : "";
            this.dataForm.edit.transactionCodeId = resp.data.data
              .mtransactionCode
              ? resp.data.data.mtransactionCode.transactionCodeId
              : "";
            this.dataForm.edit.depositChartOfAccountId =
              resp.data.data.depositChartOfAccountId;
            this.property.modal.showModalEditDepositChartOfAccount = true;
            this.dataForm.edit.pairType = resp.data.data.pairType;
          } else {
            this.$buefy.dialog.alert({
              title: "Gagal !",
              message: resp.data.errorFields
                ? this.globalNotificationErrorFieldToText(resp.data.errorFields)
                : resp.data.message,
              confirmText: "Ok",
              type: "is-danger",
            });
          }
        } catch (error) {
          console.log(error.response);
          this.$buefy.dialog.alert({
            title: "Gagal !",
            message: error.response
              ? error.response.data.errorFields
                ? this.globalNotificationErrorFieldToText(
                    error.response.data.errorFields
                  )
                : error.response.statusText
              : "Terjadi Kesalahan",
            confirmText: "Ok",
            type: "is-danger",
          });
        }
      }
    },
    async editDepositChartOfAccount() {
      const payload = {
        depositChartOfAccountId: this.dataForm.edit.depositChartOfAccountId,
        depositId: this.identity.depositId,
        chartOfAccountId: this.dataForm.edit.chartOfAccountId,
        productLedgerId: this.dataForm.edit.productLedgerId,
        mutationPosition: this.dataForm.edit.mutationPosition,
        pairChartOfAccountId: this.dataForm.edit.pairChartOfAccountId,
        pairMutationPosition: this.dataForm.edit.pairMutationPosition,
        transactionCodeId: this.dataForm.edit.transactionCodeId,
        isActive: this.dataForm.edit.isActive,
        pairType: this.dataForm.edit.pairType,
      };
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Ingin Mengedit Data ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.editDepositChartOfAccount.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "PUT_DATA",
                endPoint: "transaction",
                reqUrl:
                  "deposit-chart-of-account/" +
                  this.dataForm.edit.depositChartOfAccountId,
                payload: payload,
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.property.modal.showModalEditDepositChartOfAccount = false;
                  setTimeout(() => {
                    this.$buefy.toast.open(this.$NotificationUtils.success);
                    setTimeout(() => {
                      this.resetFormDepositChartOfAccount("EDIT");
                      this.getDepositChartOfAccount();
                    }, 500);
                  }, 1000);
                } else if (resp.data.code === "PLEASE_WAIT_IN_THE_PROCESS") {
                  this.$buefy.dialog.alert({
                    title: "Harap Tunggu !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.editDepositChartOfAccount.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    async deleteDepositChartOfAccount(props) {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Ingin Menghapus Data ?",
          type: "is-success",
          onConfirm: async () => {
            try {
              const resp = await this.$store.dispatch({
                type: "DELETE_DATA",
                endPoint: "transaction",
                reqUrl:
                  "deposit-chart-of-account/" +
                  props.row.depositChartOfAccountId,
              });
              if (resp.data.code === "SUCCESS") {
                this.$buefy.toast.open(this.$NotificationUtils.success);
                setTimeout(() => {
                  this.getDepositChartOfAccount();
                }, 1000);
              } else {
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: resp.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        resp.data.errorFields
                      )
                    : resp.data.message,
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }
            } catch (error) {
              console.log(error.response);
              this.$buefy.dialog.alert({
                title: "Gagal !",
                message: error.response
                  ? error.response.data.errorFields
                    ? this.globalNotificationErrorFieldToText(
                        error.response.data.errorFields
                      )
                    : error.response.statusText
                  : "Terjadi Kesalahan",
                confirmText: "Ok",
                type: "is-danger",
              });
            }
          },
        });
      }
    },
    getIdentityFromMixin() {
      this.identity.depositId = this.mixin_data_identifier_deposit_id;
    },
  },
  mounted() {
    this.getIdentityFromMixin();
    this.getProductCategoryNameDepositChartOfAccount();
    this.getReferenceProductLedger();
    this.getReferenceChartOfAccount();
    this.getDepositChartOfAccount();
    this.appendOptionsPosisiMutasi();
    this.getReferenceTransactionCode();
  },
};
